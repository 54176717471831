.m-accordion {
  &__headline {
    margin-bottom: get-baseline(4);

    @include mq(m) {
      margin-bottom: get-baseline(6);
    }
  }

  details {
    margin-top: get-baseline(3);

    @include mq(m) {
      margin-top: get-baseline(4);
    }
  }

  summary {
    cursor: pointer;
    display: flex;
    align-items: center;
    list-style: none;

    @include font-copy;

    font-weight: get-weight("bold");

    &::-webkit-details-marker {
      display: none;
    }
  }

  &__icon {
    width: 20px;
    height: 18px;
    margin-right: get-baseline(3);
  }

  &__content {
    margin-top: get-baseline(6);

    @include mq(m) {
      margin-top: get-baseline(8);
    }
  }

  details[open] summary {
    .m-accordion__icon {
      transform: rotate(180deg);
    }
  }
}
