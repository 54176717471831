$teaser-overlay-opacity: 0.1;
$teaser-lower-half-overlay-opacity: 0.7;
$teaser-shadow-default-mobile: 0 20px 40px -10px rgba(#000, 0.9);
$teaser-shadow-default-desktop: 0 50px 50px -25px rgba(#000, 0.9);
$teaser-shadow-hover-mobile: 0 18px 50px -10px rgba(#000, 0.75);
$teaser-shadow-hover-desktop: 0 45px 60px -25px rgba(#000, 0.75);

.m-teaser {
  $teaser: &;

  cursor: pointer;
  display: flex;
  margin-bottom: get-baseline(10); // this keeps the shadow visible
  position: relative;
  transform: translateZ(0);
  width: 100%;
  z-index: 0;

  &__inner {
    text-decoration: none;
    transition: all 400ms cubic-bezier(0.5, 1, 0.1, 1);
    width: 100%;

    &-content {
      background: get-color("grey-dark");
      position: relative;

      &::after {
        box-shadow: $teaser-shadow-default-mobile;
        content: "";
        display: inline-block;
        height: 100%;
        left: 0;
        position: absolute;
        right: 0;
        top: 0;
        transition: all 400ms cubic-bezier(0.5, 1, 0.1, 1);
        z-index: -1;

        @include mq(m) {
          box-shadow: $teaser-shadow-default-desktop;
        }
      }
    }
  }

  &__image {
    display: block;
    height: auto;
    transition: all 0.3s ease;
    width: 100%;
  }

  &__image-wrapper {
    pointer-events: none; // no need to interact with this element
    position: relative;
    z-index: 1;
    overflow: hidden;

    &::before,
    &::after {
      content: "";
      left: 0;
      position: absolute;
      width: 100%;
      top: 0;
    }

    // Background
    &::after {
      background-color: get-color("black");
      height: 100%;
      opacity: $teaser-overlay-opacity;
      z-index: 1;
    }

    // The actual image
    img {
      width: 100%;
      display: block;
      position: absolute;
      top: 0;
      left: 0;
      z-index: 2;
      object-fit: cover;
      height: 100%;
    }

    &[style*="--ratio"] {
      height: 0;
      padding-bottom: calc(100% / (var(--ratio)));
      position: relative;
      box-sizing: border-box;
      background-color: get-color("grey");

      img {
        position: absolute;
        height: 100%;
        width: 100%;
        left: 0;
        top: 0;
        object-fit: cover;
      }
    }
  }

  &__center-logo {
    align-items: center;
    bottom: 0;
    display: flex;
    justify-content: center;
    left: 0;
    position: absolute;
    right: 0;
    top: 0;
    z-index: 2;

    &-image {
      background-position: center;
      background-repeat: no-repeat;
      background-size: contain;
      display: block;
    }
  }

  &__headline {
    @include font-headline-small;

    text-align: left;
    color: get-color("white");
    margin: 0;
    position: relative;
    transform: translateZ(
      0
    ); // This is needed to fix strange glitch on search layer

    white-space: initial;
    width: 100%;
    word-wrap: break-word;
    z-index: 2;

    &-wrapper {
      align-items: flex-end;
      bottom: 0;
      display: flex;
      left: 0;
      padding: get-baseline(2);
      position: absolute;
      right: 0;
      top: 0;
      z-index: 2;

      &::before {
        content: "";
        background-image: linear-gradient(
          to bottom,
          transparent,
          rgba(get-color("black"), $teaser-lower-half-overlay-opacity)
        );
        height: 50%;
        left: 0;
        position: absolute;
        top: 50%;
        width: 100%;
      }
    }

    &-counter {
      font-size: 60pt;
    }
  }

  &__badge {
    display: inline-block;
    margin: get-baseline(1) get-baseline(1) 0 0;
    max-height: get-baseline(4);
    max-width: get-baseline(17);
    pointer-events: none;
    position: absolute;
    right: 0;
    top: 0;
    z-index: 4;
  }

  // photoswipe icon
  &__action-icon {
    color: get-color("white");
    pointer-events: none;
    position: absolute;
    right: get-baseline(1);
    top: get-baseline(1);

    .a-svg-sprite {
      height: get-baseline(4);
      width: get-baseline(4);
    }
  }

  // Statuslane wrapper styles
  &__statuslane-wrapper {
    align-items: flex-start;
    display: flex;
    position: relative;
  }

  // Teaser that has only an image
  &--picture & {
    &__headline-wrapper,
    &__statuslane-wrapper {
      display: none;
    }
  }

  // Hover effect
  &:hover {
    z-index: 1;
  }

  &:hover & {
    &__inner {
      transform: scale(1.04);

      &-content {
        &::after {
          box-shadow: $teaser-shadow-hover-mobile;

          @include mq(m) {
            box-shadow: $teaser-shadow-hover-desktop;
          }
        }
      }
    }
  }
}
