.o-ticket-stage {
  $this: &;

  @include grid-container;

  &__wrapper {
    @include grid-container;
    @include grid-item(1);
  }

  &__headline {
    display: block;
    display: flex;
    justify-content: flex-end;
    flex-direction: column;
    flex: 1;
    margin-bottom: get-baseline(5);
  }

  &__content {
    color: get-color("white");
    padding: get-baseline(7);
    text-align: center;

    @include mq(l) {
      display: flex;
      flex-direction: column;
      flex-grow: 1;
      text-align: left;
    }

    &__headline {
      @include font-headline-large;

      margin-bottom: get-baseline(5);

      @include mq(m) {
        margin-bottom: get-baseline(7);
      }
    }
  }

  &__image-wrapper {
    position: relative;
  }

  &__back-btn-wrapper {
    @include grid-item(2, -2);

    margin-bottom: get-baseline(1);

    @include mq(m) {
      margin-bottom: get-baseline(3);
    }
  }

  &__back-btn {
    border: 0;
    padding: 0;
  }

  &__inner-wrapper {
    @include default-box-shadow;
    @include grid-item(2, -2);

    background: get-color("black");

    @include mq($until: l) {
      display: flex;
      flex-direction: column-reverse;
    }

    @include mq(l) {
      @include grid-container(12);
    }

    #{$this}__content {
      @include mq(l) {
        @include grid-item(1, 4, "desktop");
      }
    }

    #{$this}__image-wrapper {
      @include mq(l) {
        @include grid-item(5, 12, "desktop");
      }
    }
  }

  &__image {
    @include mq(l) {
      height: 100%;
      width: 100%;
    }

    img {
      width: 100%;
      height: auto;

      @include mq(l) {
        height: 100%;
        object-fit: contain;
        object-position: top center;
      }
    }
  }

  &__countdown {
    &--is-hidden {
      display: none;
    }
  }

  &__states {
    align-items: center;
    display: flex;
    flex: 1;
    justify-content: center;

    @include mq(l) {
      justify-content: flex-start;
    }
  }

  &__state {
    display: none;

    &__headline {
      @include font-headline-small;

      color: get-color("white-dark");
      display: block;
    }

    &__tooLate {
      @include font-headline-small;

      color: get-color("magenta");
      display: block;
      margin: get-baseline(2) 0;
    }

    &__date {
      @include font-headline-medium(true);

      display: block;
      margin: get-baseline(2) 0;
    }

    &__countdown {
      margin: get-baseline(1) 0 get-baseline(7);
    }

    &__text {
      @include font-copy-small;

      margin-top: get-baseline(3);
    }

    &__button {
      margin: get-baseline(7) 0 0;
    }

    // &--available-at {
    //
    // }

    // &--expires-at {
    //
    // }

    // &--expired {
    //
    // }
    //
    &--is-active {
      display: block;
    }
  }

  &__description {
    @include grid-item(2, -2);

    margin-top: get-baseline(6);

    @include mq(m) {
      @include grid-item(3, -3, "desktop");
    }
  }
}
