.m-social-tile {
  background-color: get-color("grey-dark");
  box-shadow: 0 20px 40px -10px rgba(#000, 0.9);
  position: relative;
  text-decoration: none;
  width: 100%;
  height: 100%;
  overflow: auto;
  display: block;
  scrollbar-width: none;
  -ms-overflow-style: none;

  &::-webkit-scrollbar {
    display: none;
  }

  // background image
  &__background {
    width: 100%;
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;

    img {
      object-fit: cover;
      width: 100%;
      height: 100%;
    }
  }

  // content section
  &__content {
    justify-content: space-between;
    color: get-color("white");
    display: flex;
    flex-flow: column;
    padding: get-baseline(2);
    position: relative;
    width: 100%;
    height: 100%;
  }

  &__container {
    &--top {
      //
    }

    &--bottom {
      //
    }
  }

  &__text {
    display: none;
    margin-top: get-baseline(1);

    @include mq(m) {
      display: block;
    }
  }

  // social area
  &__social {
    display: flex;
    flex-wrap: wrap;
    margin-top: get-baseline(2);

    > * {
      &:not(:last-child) {
        margin-right: get-baseline(1);
      }
    }
  }
}
