.o-ticket-list {
  $module: &;

  &__headline {
    color: get-color("white-dark");
    flex-grow: 2;
  }

  &__headline-wrapper {
    display: flex;
    margin: get-baseline(1) 0 get-baseline(3);

    @include mq($until: m) {
      flex-direction: column;
    }
  }

  &__item {
    @include default-box-shadow;
    @include grid-container(6);

    background-color: get-color("grey-medium");
    padding: get-baseline(6) get-baseline(3);
    margin-bottom: get-baseline(6);

    @include mq(m) {
      @include grid-container(12);

      padding: 0;
    }

    &-content {
      @include grid-item(1, 6);

      @include mq(m) {
        @include grid-item(5, 11);

        padding: get-baseline(6) 0;
      }

      @media (min-width: 1280px) {
        @include grid-item(4, 11);
      }
    }

    &-date {
      @include grid-item(1, 6);

      margin-bottom: get-baseline(3);

      @include mq(m) {
        @include grid-item(1, 4);

        align-items: center;
        display: flex;
        justify-content: center;
        margin: 0;
        padding: get-baseline(6);
      }

      @media (min-width: 1280px) {
        @include grid-item(1, 3);
      }
    }

    &__address {
      font-style: normal; // reset <address> default styles
      margin: get-baseline(1) 0 get-baseline(2) 0;
    }
  }

  &__call-to-action {
    display: none;
    justify-content: end;
    margin: get-baseline(3) 0 0;
  }

  &__sold-out {
    margin: get-baseline(3) 0 0;
  }

  &__additional-links {
    margin: get-baseline(6) 0;

    > * {
      margin: 0 get-baseline(2) get-baseline(2) 0;

      @include mq(m) {
        margin: 0 get-baseline(1) get-baseline(2) 0;
      }

      &:last-child {
        margin-bottom: 0;
        margin-right: 0;
      }
    }
  }

  &__link {
    margin-right: get-baseline(1);
  }

  &__purchase {
    display: none;
  }

  &__item-artist {
    margin: get-baseline(2) 0 get-baseline(1);
  }

  &__state {
    &--available,
    &--expired,
    &--upcoming {
      display: none;
    }
  }

  &--is-upcoming {
    #{$module}__state--upcoming {
      display: block;
    }

    #{$module}__call-to-action {
      display: none;
    }
  }

  &--is-available {
    color: get-color("white");

    #{$module}__state--available {
      display: block;
    }

    #{$module}__call-to-action {
      display: flex;
    }
  }

  &--is-expired {
    #{$module}__state--expired {
      display: block;
    }

    #{$module}__call-to-action {
      display: none;
    }
  }
}
