.m-page-title {
  margin-bottom: get-baseline(7);

  &--narrow {
    margin-bottom: get-baseline(4);
  }

  h1 {
    @include font-headline-large;
  }

  &--narrow h1 {
    @include font-headline-medium(true);
  }

  h2 {
    @include font-headline-medium;

    margin-top: get-baseline(5);
  }

  &--narrow h2 {
    @include font-headline-small;
  }

  h5 {
    @include font-copy;

    font-weight: get-weight("bold");
    margin-bottom: get-baseline(2);
  }
}
