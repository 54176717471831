@keyframes loadingPlaceholder {
  0% {
    background-position: -468px 0;
  }

  100% {
    background-position: 468px 0;
  }
}

.o-teaser-grid {
  &__wrapper {
    @include grid-container;

    margin: 0 auto;
  }

  &__header {
    @include grid-item(2, -2);

    margin-bottom: get-baseline(10);

    @include mq(l) {
      display: flex;
      justify-content: space-between;
      align-items: center;
    }
  }

  &__teasers {
    @include grid-item(2, -2);

    display: grid;
    grid-template-columns: 1fr;
    grid-column-gap: get-baseline(3);
    grid-row-gap: get-baseline(7);

    @include mq(m) {
      grid-row-gap: get-baseline(11);
      grid-template-columns: repeat(2, 1fr);
    }

    @include mq(l) {
      grid-template-columns: repeat(3, 1fr);
    }
  }

  &__teaser {
    margin-bottom: 0;
  }

  &__load-more {
    @include grid-item(2, -2);

    display: flex;
    justify-content: center;
    margin-top: get-baseline(10);
  }

  &__loading {
    height: 0;
    padding-bottom: calc(100% / 1.7777);
    position: relative;
    box-sizing: border-box;
    animation-duration: 1.8s;
    animation-fill-mode: forwards;
    animation-iteration-count: infinite;
    animation-name: loadingPlaceholder;
    animation-timing-function: linear;
    background: get-color("grey");
    background: linear-gradient(
      to right,
      get-color("grey") 8%,
      get-color("grey-light") 38%,
      get-color("grey") 54%
    );
    background-size: 1000px 640px;
    margin-bottom: get-baseline(5);
  }

  &__categories {
    margin-top: get-baseline(2);

    @include mq(m) {
      margin-top: 0;
      display: flex;
      align-items: center;
      justify-self: flex-end;
    }

    p {
      margin-right: get-baseline(2);
      margin-top: get-baseline(1);
      flex-grow: 2;
    }

    button {
      margin-right: get-baseline(1);

      &:last-of-type {
        margin-right: 0;
      }
    }

    &--placeholder {
      min-height: get-baseline(6);
    }
  }

  &__select {
    position: relative;

    select {
      appearance: none;
      -moz-appearance: none;
      -webkit-appearance: none;
      padding-right: get-baseline(4);
    }

    svg {
      position: absolute;
      width: 24px;
      height: 24px;
      right: get-baseline(1);
      top: 50%;
      transform: translateY(-50%);
    }
  }
}
