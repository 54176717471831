$overflow-offset: get-baseline(3);
$social-tile-wrapper: ".o-teaser-lane__slide--social-tile";
$social-tile-component: ".m-social-tile";
$teaser-lane-breakpoint-1024: 1024px;
$teaser-lane-breakpoint-1200: 1200px;

.o-teaser-lane {
  $this: &;

  overflow: hidden;
  margin-bottom: get-baseline(
    -10
  ); // Optischer Ausgleich zu .m-teaser margin-bottom

  position: relative;

  &__background-image {
    pointer-events: none;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    opacity: 0.5;
    z-index: 1;

    &::before {
      @include gradient;

      content: "";
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      opacity: 0.3;
    }

    img {
      object-fit: cover;
      width: 100%;
      height: 100%;
    }
  }

  &--no-background-shade &__background-image {
    opacity: 1;

    &::before {
      display: none;
    }
  }

  &__header {
    @include grid-container;

    margin-bottom: get-baseline(1);
    position: relative;
    z-index: 2;

    @include mq($until: m) {
      display: flex;
      flex-direction: column-reverse;
      margin: 0 map-deep-get($grid-configuration, "mobile", "spaceAround");
      width: auto;
    }

    &__text-wrapper {
      @include mq($until: m) {
        @include grid-size("width", "mobile", 6);
      }

      @include mq(m) {
        @include grid-item(2, -2, "desktop");
      }
    }

    &__logo-wrapper {
      @include mq($until: m) {
        width: get-baseline(12);
        height: get-baseline(12);
        align-self: flex-end;
        margin-bottom: get-baseline(3);
      }

      @include mq(m) {
        @include grid-item(12, 13, "desktop");

        display: flex;
        justify-content: flex-end;
        margin-top: get-baseline(-5);
      }
    }
  }

  &__headline {
    &__wrapper {
      align-items: center;
      display: flex;
      position: relative;
    }
  }

  &__text {
    margin: get-baseline(4) 0 get-baseline(2);

    @include mq(m) {
      margin: get-baseline(5) 0 get-baseline(3);
    }
  }

  &__logo {
    @include mq(m) {
      width: get-baseline(20);
      height: get-baseline(20);
    }

    img {
      width: 100%;
      height: 100%;
      object-fit: fill;
    }
  }

  &__body {
    position: relative;
    z-index: 2;
  }

  &__wrapper {
    position: relative;
    padding-top: get-baseline(2);
    z-index: 2;

    &::before,
    &::after {
      position: absolute;
      top: 0;
      height: 100%;
      width: 40px;
      content: "";
      z-index: 9;
    }

    // &::before {
    //   left: 0;
    //   background: linear-gradient(
    //     90deg,
    //     get-color("grey-dark") 0%,
    //     rgba(get-color("grey-dark"), 0.7) 20%,
    //     rgba(get-color("grey-dark"), 0.3) 55%,
    //     rgba(get-color("grey-dark"), 0) 100%
    //   );
    // }

    // &::after {
    //   right: 0;
    //   background: linear-gradient(
    //     -90deg,
    //     get-color("grey-dark") 0%,
    //     rgba(get-color("grey-dark"), 0.7) 20%,
    //     rgba(get-color("grey-dark"), 0.3) 55%,
    //     rgba(get-color("grey-dark"), 0) 100%
    //   );
    // }
  }

  &__slides {
    display: flex;
    -webkit-overflow-scrolling: touch;
    scrollbar-width: none;
    -ms-overflow-style: none;
    overflow-x: scroll;
    overflow-y: hidden;
    padding: $overflow-offset
      map-deep-get($grid-configuration, "mobile", "spaceAround");
    margin: calc(#{$overflow-offset} * -1) 0;
    user-select: none;
    gap: map-deep-get(
      $grid-configuration,
      "mobile",
      "gutter"
    ); // space between slides

    scroll-padding-left: map-deep-get(
      $grid-configuration,
      "mobile",
      "spaceAround"
    );
    scroll-snap-type: x mandatory;

    @supports (overflow: -moz-scrollbars-none) {
      overflow: -moz-scrollbars-none;
    }

    &::-webkit-scrollbar {
      display: none;
    }

    @include mq(m) {
      gap: map-deep-get(
        $grid-configuration,
        "desktop",
        "gutter"
      ); // space between slides
      padding: $overflow-offset
        map-deep-get($grid-configuration, "desktop", "spaceAround");
      scroll-padding-left: map-deep-get(
        $grid-configuration,
        "desktop",
        "spaceAround"
      );
    }
  }

  // single slide item in teaser lane
  &__slide {
    @include grid-size("max-width", "mobile", 5);

    align-items: flex-start;
    display: flex;
    flex: 1 0 100%;
    height: 100%;
    position: relative;
    z-index: 3;
    scroll-snap-align: start;

    @include mq(m) {
      @include grid-size("max-width", "desktop", 4);
    }

    &#{$social-tile-wrapper} {
      height: calc(100% - #{get-baseline(15)});
      position: relative;
      overflow: hidden;

      #{$social-tile-component} {
        position: absolute;
        top: 0;
        left: 0;
      }
    }
  }

  // Controls
  &__control-wrapper {
    align-items: center;
    bottom: get-baseline(5);
    display: flex;
    opacity: 0;
    position: absolute;
    top: 0;
    transition: opacity 0.3s ease, transform 0.3s ease;

    @include mq(s) {
      bottom: get-baseline(8);
    }

    @include mq(m) {
      bottom: get-baseline(12);
    }

    @include mq(l) {
      bottom: get-baseline(14);
    }

    &--next {
      padding-right: get-baseline(5);
      right: 0;
      transform: translateX(100%);
      z-index: 999;
    }

    &--prev {
      left: 0;
      padding-left: get-baseline(5);
      transform: translateX(-100%);
      z-index: 999;
    }

    &--hidden {
      opacity: 0 !important;
      pointer-events: none;
    }
  }

  [data-whatintent="mouse"] &__wrapper:hover &__control-wrapper {
    opacity: 1;
    transform: none;
  }

  &__control {
    appearance: none;
    border: none;
    width: get-baseline(7);
    height: get-baseline(7);
    border-radius: 500px;
    cursor: pointer;
    background: get-color("white");
    color: get-color("magenta");

    &:hover,
    &:focus {
      background: get-color("magenta");
      color: get-color("white");
    }

    svg {
      width: 100%;
      height: 100%;
    }
  }

  &__control-wrapper--prev &__control svg {
    transform: rotate(180deg);
  }

  // So there are 3 different variants of the teaser-lane component, which define how many columns each item within the teaser-lane should soan:
  // - s
  // - m (default)
  // - l
  //
  // There is also 2 different aspect-ratios being used, which we have to take into consideration:
  // - wide (default)
  // - slim
  //
  // There is also the social teaser (m-social-tile), which is a Störelement and can have a different size.

  // S
  &#{$this}--s {
    // Slim
    &#{$this}--slim {
      // Slide
      #{$this}__slide {
        @include grid-size("max-width", "mobile", 5);

        @include mq(m) {
          @include grid-size("max-width", "desktop", 3);
        }

        @include mq(l) {
          @include grid-size("max-width", "desktop", 2);
        }

        @include mq($maxWidth) {
          @include grid-size("max-width", "desktop", 2, $maxWidth);
        }
      }

      // Social teaser
      #{$social-tile-wrapper} {
        @include grid-size("max-width", "mobile", 3);

        @include mq(m) {
          @include grid-size("max-width", "desktop", 3);
        }

        @include mq(l) {
          @include grid-size("max-width", "desktop", 3);
        }

        @include mq($maxWidth) {
          @include grid-size("max-width", "desktop", 3, $maxWidth);
        }
      }
    }

    // Wide
    &#{$this}--wide {
      // Slide
      #{$this}__slide {
        @include grid-size("max-width", "mobile", 5);

        @include mq(m) {
          @include grid-size("max-width", "desktop", 6);
        }

        @include mq($teaser-lane-breakpoint-1024) {
          @include grid-size("max-width", "desktop", 4);
        }

        @include mq($teaser-lane-breakpoint-1200) {
          @include grid-size("max-width", "desktop", 3);
        }

        @include mq(l) {
          @include grid-size("max-width", "desktop", 3);
        }

        @include mq($maxWidth) {
          @include grid-size("max-width", "desktop", 3, $maxWidth);
        }
      }

      // Social teaser
      #{$social-tile-wrapper} {
        @include grid-size("max-width", "mobile", 4);

        @include mq(m) {
          @include grid-size("max-width", "desktop", 4);
        }

        @include mq(l) {
          @include grid-size("max-width", "desktop", 3);
        }

        @include mq($maxWidth) {
          @include grid-size("max-width", "desktop", 3, $maxWidth);
        }

        #{$social-tile-component}__text {
          display: none;
        }
      }
    }
  }

  // M
  &#{$this}--m {
    // Slim
    &#{$this}--slim {
      // Slide
      #{$this}__slide {
        @include grid-size("max-width", "mobile", 5);

        @include mq(m) {
          @include grid-size("max-width", "desktop", 3);
        }

        @include mq(l) {
          @include grid-size("max-width", "desktop", 2);
        }

        @include mq($maxWidth) {
          @include grid-size("max-width", "desktop", 2, $maxWidth);
        }
      }

      // Social teaser
      #{$social-tile-wrapper} {
        @include grid-size("max-width", "mobile", 3);

        @include mq(m) {
          @include grid-size("max-width", "desktop", 3);
        }

        @include mq(l) {
          @include grid-size("max-width", "desktop", 3);
        }

        @include mq($maxWidth) {
          @include grid-size("max-width", "desktop", 3, $maxWidth);
        }
      }
    }

    // Wide
    &#{$this}--wide {
      // Slide
      #{$this}__slide {
        @include grid-size("max-width", "mobile", 5);

        @include mq(m) {
          @include grid-size("max-width", "desktop", 6);
        }

        @include mq($teaser-lane-breakpoint-1024) {
          @include grid-size("max-width", "desktop", 4);
        }

        @include mq($teaser-lane-breakpoint-1200) {
          @include grid-size("max-width", "desktop", 4);
        }

        @include mq(l) {
          @include grid-size("max-width", "desktop", 4);
        }

        @include mq($maxWidth) {
          @include grid-size("max-width", "desktop", 4, $maxWidth);
        }
      }

      // Social teaser
      #{$social-tile-wrapper} {
        @include grid-size("max-width", "mobile", 4);

        @include mq(m) {
          @include grid-size("max-width", "desktop", 6);
        }

        @include mq($teaser-lane-breakpoint-1024) {
          @include grid-size("max-width", "desktop", 5);
        }

        @include mq($teaser-lane-breakpoint-1200) {
          @include grid-size("max-width", "desktop", 4);
        }

        @include mq(l) {
          @include grid-size("max-width", "desktop", 3);
        }

        @include mq($maxWidth) {
          @include grid-size("max-width", "desktop", 3, $maxWidth);
        }
      }
    }
  }

  // L
  &#{$this}--l {
    // Slim
    &#{$this}--slim {
      // Slide
      #{$this}__slide {
        @include grid-size("max-width", "mobile", 5);

        @include mq(m) {
          @include grid-size("max-width", "desktop", 3);
        }

        @include mq(l) {
          @include grid-size("max-width", "desktop", 3);
        }

        @include mq($maxWidth) {
          @include grid-size("max-width", "desktop", 3, $maxWidth);
        }
      }

      // Social teaser
      #{$social-tile-wrapper} {
        @include grid-size("max-width", "mobile", 3);

        @include mq(m) {
          @include grid-size("max-width", "desktop", 3);
        }

        @include mq(l) {
          @include grid-size("max-width", "desktop", 3);
        }

        @include mq($maxWidth) {
          @include grid-size("max-width", "desktop", 3, $maxWidth);
        }
      }
    }

    // Wide
    &#{$this}--wide {
      // Slide
      #{$this}__slide {
        @include grid-size("max-width", "mobile", 5);

        @include mq(m) {
          @include grid-size("max-width", "desktop", 6);
        }

        @include mq(l) {
          @include grid-size("max-width", "desktop", 6);
        }

        @include mq($maxWidth) {
          @include grid-size("max-width", "desktop", 6, $maxWidth);
        }
      }

      // Social teaser
      #{$social-tile-wrapper} {
        @include grid-size("max-width", "mobile", 4);

        @include mq(m) {
          @include grid-size("max-width", "desktop", 6);
        }

        @include mq($teaser-lane-breakpoint-1024) {
          @include grid-size("max-width", "desktop", 4);
        }

        @include mq($teaser-lane-breakpoint-1200) {
          @include grid-size("max-width", "desktop", 3);
        }

        @include mq(l) {
          @include grid-size("max-width", "desktop", 3);
        }

        @include mq($maxWidth) {
          @include grid-size("max-width", "desktop", 3, $maxWidth);
        }
      }
    }
  }

  // Highlight
  &--highlight {
    background-color: get-color("black");
    padding: get-baseline(5) 0 0;
    margin-bottom: 0;

    @include mq(m) {
      padding: get-baseline(10) 0 0;
    }
  }

  // With background image (extends --highlight)
  &--has-background-image {
    position: relative;
    background-color: get-color("black");

    .o-teaser-lane__wrapper {
      &::before,
      &::after {
        display: none;
      }
    }
  }
}
