.o-hero-teaser {
  @include grid-container();

  z-index: 1;
  grid-row-gap: get-baseline(4);

  @include mq(m) {
    grid-row: 1;
  }

  &__topline {
    max-width: 100px;
    margin-bottom: get-baseline(-3);
    @include grid-item(2, 3);

    @include mq(m) {
      @include grid-item(3, 4);
    }
  }

  &__logo-wrapper {
    max-width: 100px;
    @include grid-item(2, 3);

    @include mq(m) {
      @include grid-item(3, 4);
    }
  }

  &__logo {
    @include ratio-container(16, 9);
  }

  &__headline {
    @include grid-item(2, 6);
    max-width: 650px;

    @include mq(m) {
      @include grid-item(3, 12);
    }

    & > span {
      margin-bottom: get-baseline(3);
    }
  }

  &__intro {
    @include grid-item(2, 6);
    max-width: 520px;

    @include mq(m) {
      @include grid-item(4, 12);
    }
  }

  &__image-wrapper {
    position: relative;
    @include grid-item(1, -1);
    @include grid-container();
    grid-row: 5;
  }

  &__image {
    grid-row-gap: get-baseline(4);
    // margin-bottom: get-baseline(6);

    @include ratio-container(16, 9);

    @include mq(m) {
      grid-row: 1;
    }
  }

  /* stylelint-disable -- no-descending-specificity */
  &__image-wrapper--three &__image {
    &:nth-child(1) {
      z-index: 3;
      @include grid-item(2, 6);

      @include mq(m) {
        @include grid-item(2, 6);
      }
    }

    &:nth-child(2) {
      @include grid-item(3, 7);
      margin-top: get-baseline(-3);
      z-index: 2;

      @include mq(m) {
        @include grid-item(6, 10);
        margin-top: 80px;
      }
    }

    &:nth-child(3) {
      @include grid-item(2, 6);
      margin-top: get-baseline(-3);
      z-index: 1;

      @include mq(m) {
        @include grid-item(9, 13);
        margin-top: -30px;
      }
    }
  }

  &__image-wrapper--two &__image {
    &:nth-child(1) {
      @include grid-item(2, 6);
      z-index: 2;

      @include mq(m) {
        @include grid-item(2, 7);
      }
    }

    &:nth-child(2) {
      @include grid-item(3, 7);
      margin-top: get-baseline(-3);
      z-index: 1;
      @include mq(m) {
        @include grid-item(7, 13);
        margin-top: 70px;
      }
    }
  }

  /* stylelint-enable */
  &__button-bundle {
    display: flex;
    flex-flow: column wrap;
    align-items: flex-start;

    @include grid-item(2, 6);

    @include mq(m) {
      @include grid-item(4, 8);
    }

    > *:not(:last-child) {
      margin-bottom: get-baseline(2);
    }
  }
}
