.o-article-intro {
  @include grid-container;

  grid-row-gap: get-baseline(4);
  margin-bottom: get-baseline(6);

  &__headline,
  &__intro {
    @include grid-item(2, -2, "mobile");

    @include mq(m) {
      @include grid-item(2, -3, "desktop");
    }
  }

  &__headline {
    @include mq(l) {
      @include grid-item(3, -5, "desktop");
    }

    & > span {
      margin-bottom: get-baseline(1);
    }
  }

  &__intro {
    @include mq(l) {
      @include grid-item(4, -5, "desktop");
    }
  }
}
