.t-landing-page {
  position: relative;

  &__grid {
    @include grid-container;

    position: relative;
  }

  &__intro {
    z-index: 1;

    @include grid-item(1);
  }

  &__tags {
    @include grid-item(2, -2);

    align-items: center;
    display: flex;
    flex-wrap: wrap;
    gap: get-baseline(1);
    margin: get-baseline(6) 0 get-baseline(-1);
  }

  &__excerpt {
    @include grid-item(2, -2);

    z-index: 1;
    margin: 0 0 get-baseline(13) 0;

    @include mq(m) {
      @include grid-item(2, -6, "desktop");
    }
  }

  &__stage {
    @include grid-item(1);

    margin: 0 0 get-baseline(7);
  }

  &__stage-icons {
    @include grid-item(2, -2);

    margin: get-baseline(10) 0 0;

    @include mq(m) {
      @include grid-item(2, -2, "desktop");
    }
  }

  &__intro-teaser {
    @include grid-item(2, -2);
  }

  &__page-description {
    @include grid-item(1);
  }

  .o-teaser-rows {
    @include mq(m) {
      margin-top: get-baseline(10 - 4);
    }
  }
}
