.m-teaser-statuslane {
  @include font-copy-small;

  color: get-color("white-dark");
  display: flex;
  width: 100%;

  &--magenta {
    color: get-color("magenta");
  }

  &__state {
    display: none;
    height: 100%;
    margin-right: get-baseline(1);

    &--is-active {
      display: flex;
      align-items: center;
    }
  }

  &__tag {
    margin-right: get-baseline(1);
    background: get-color("grey-medium");
    color: get-color("white");
    padding: get-baseline(1);
    font-weight: get-weight("bold");
  }

  &__tag > svg {
    margin: get-baseline(-1);
  }

  &__tag,
  &__content,
  &__countdown-wrapper {
    align-items: center;
    display: inline-flex;
    flex: 0 0 auto;

    span {
      &.live-content {
        display: inline;

        & > span:first-child {
          margin: 0 get-baseline(2) 0 get-baseline(1);
        }
      }
    }
  }

  &__state--expires-at-expired {
    justify-content: space-between;

    .m-teaser-statuslane__content {
      flex: initial;
    }
  }

  &__state--available-at-expired,
  &__state--expires-at-expired {
    text-align: center;
  }

  &__countdown-wrapper {
    &--is-hidden {
      display: none;
    }

    .a-countdown {
      font-weight: get-weight("bold");
    }
  }

  .o-teaser-lane--ticket &__state--expires-at-running &__tag,
  .m-teaser--ticket &__state--expires-at-running &__tag,
  &--ticket &__state--expires-at-running &__tag,
  .o-teaser-lane--ticket &__state--available-at-expired &__tag,
  .m-teaser--ticket &__state--available-at-expired &__tag,
  &--ticket &__state--available-at-expired &__tag {
    background: get-color("magenta");
    color: get-color("white");
  }
}
